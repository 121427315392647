.bottombar-component{
    width: 100%;
    /* height: 100vh; */
    background-color: black;

}

/* src/BottomNav.css */
.bottombar-component .bottom-nav {
    z-index: 10;
    border-top: 1px solid #ddd;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.bottombar-component .bottom-nav .nav-link {
    text-align: center;
    font-size: 14px;
}

.bottombar-component .bottom-nav .nav-link i {
    display: block;
    font-size: 18px;
    margin-bottom: 5px;
}

.bottombar-component .bottom-nav .nav-link.active {
    color: #007bff;
}

.bottombar-component .bottom-nav .nav-link:hover {
    color: #0056b3;
}
