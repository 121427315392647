.Diary-card .blog-media {
    position: relative;
    width: 100%;
    height: 150px;
    overflow: hidden;
  }
  .Diary-card .blog-img {
    overflow: hidden;
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }
  .Diary-card .blog-title {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .Diary-card .blog-img img {
    width: 100%;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all linear 0.3s;
    -webkit-transition: all linear 0.3s;
    -moz-transition: all linear 0.3s;
    -ms-transition: all linear 0.3s;
    -o-transition: all linear 0.3s;
  }
 .Diary-card .blog-card:hover .blog-img img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }