.ALb-component {
  border-radius: 50px;
  display: flex;
  justify-content: center;
  background-color: white;
  width: 40%;
  height: 49px;
}

.ALb-component .ALb-inner {
  background-color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: visible;
  padding: 5px 5px;
  border-radius: 50px;
}

.ALb-component .searchBox {
  display: flex;
  align-items: center;
  position: relative;
}

.ALb-component .autocomplete-dropdown-container {
  z-index: 9999; 
  background-color: white;
  position: absolute; 
  /* width: 100%;  */
  width: 35%;
  /* border: 1px solid #ccc; */
  color: #000;
  border-radius: 4px;
  max-height: 200px; 
  overflow-y: auto; 
 
}

.ALb-component .autocomplete-dropdown-container .suggestion-item {
  padding: 8px 10px;
  cursor: pointer;
}

.ALb-component .autocomplete-dropdown-container .suggestion-item--active {
  background-color: #e0e0e0;
  color: black;
}

.ALb-component .find-food-btn-container {
  display: flex;
  justify-content: center;
  margin-left: 5px;
  /* max-height: 44px; */
  justify-content: end;
}

.ALb-component .find-food-btn {
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #ffffff;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.ALb-component .btn-component{
  background-color: #0056b3;
  color: white!important;
  border-radius: 50px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
font-size: medium;

}  
/* .ALb-component .btn-component .locate-text{
  width: ;
} */
.ALb-component .find-food-btn:hover {
  background-color: #0056b3;
}
/* responsive */

@media (max-width: 767.98px) {
  .ALb-component{
    width: 99%;
  }
 
}

